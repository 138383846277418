.lower-part[data-v-67777f9a]{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    min-height: 40px;
    gap: 10px;
    width: 100%;
    /* padding-left: 25px;
    padding-right: 25px; */
    padding-bottom: 10px;
}
.close-comment-icon[data-v-67777f9a]{
    padding: 0;
    cursor: pointer ;
    /* margin-right: 25px; */
    background-color: transparent;
    border: none;
}
.close-comment-icon[data-v-67777f9a]:disabled{
    pointer-events: none;
    opacity: 0.5;
    cursor:auto !important;
    cursor:initial !important;
}
.bordered-icons[data-v-67777f9a]{
    border: 1px solid var(--text-clr);
    padding: 0.2em 0.5em;
    border-radius: 50%;
}
.flex-likes-answers[data-v-67777f9a]{
    display: flex;
    flex-direction: row;
    padding-right: 4px;
    gap: 10px;
    justify-content: space-between;
}
.flex-answers-arrows[data-v-67777f9a]{
    min-width: 100px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 7px;
    gap: 10px;
}
.flex-likes-minus[data-v-67777f9a]{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 93px;
    justify-content: flex-end;
}
.flex-comment[data-v-67777f9a]{
    border-radius: 10px;
    /* max-width: 474px; */
    /* padding-top: 10px; */
    gap: 10px;
 
        /* height: 38px; */
    display: flex;
    flex-grow: 1;

    padding: 8px;
    align-items: center;
    /* justify-content: flex-start; */
}
.flex-comment button[data-v-67777f9a]{
    border: none;
    display: flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.flex-comment textarea[data-v-67777f9a]{
    resize: none;
    background-color: transparent;
    border:none;
    /* padding: 10px 10px 0px 10px; */
    outline: none;
    min-height: 20px;
    width: 80%;
    font-family: Assistant;
    font-size: 16px;
    /* height: 38px; */
    font-style: normal;
    font-weight: 400;
}
.close-chatbox-btn[data-v-67777f9a]{
  margin-left: auto;
}
.active-bg[data-v-67777f9a]{
    background-color: var(--lighter-grey-clr);
}
.unactive-bg[data-v-67777f9a]{
    background-color: transparent;
}