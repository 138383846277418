.search-container[data-v-f1317d3a] {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
  padding: 0 60px;
  margin-bottom: 35px;
}
.bots-table[data-v-f1317d3a] {
  overflow: auto;
  max-height: calc(100vh - 236px);
}