body[data-v-f5ff019b] {
  overflow-y: scroll;
}
tbody tr[data-v-f5ff019b] {
  background-color: var(--white-clr);
  border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-f5ff019b] {
  display: none;
}
tr[data-v-f5ff019b] {
  width: 100%;
}
thead[data-v-f5ff019b] {
  width: 100px;
}
.vector-modal-btn[data-v-f5ff019b] {
  background-color: transparent;
  border: 0;
  outline: 0;
}
.flex-vector-methods[data-v-f5ff019b] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}
.flex-vector-methods button[data-v-f5ff019b] {
  font-size: 18px;
  display: flex;
  gap: 16px;
}
thead th[data-v-f5ff019b] {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* z-index: 2; */
  background-color: var(--chat-background-clr);
  padding: 13px 10px;
}
tr img[data-v-f5ff019b] {
  width: 18px;
  height: 18px;
}
td[data-v-f5ff019b],
th[data-v-f5ff019b] {
  min-width: 120px;
}
table[data-v-f5ff019b] {
  border: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.table-container[data-v-f5ff019b] {
  overflow: auto;
  max-height: calc(100vh - 220px);
}
td[data-v-f5ff019b] {
  border-right: none;
  border-left: none;
  font-size: 18px;
  vertical-align: top;
  padding: 13px 5px;
}
.icon-name-container[data-v-f5ff019b] {
  display: flex;
  gap: 8px;
  font-family: Assistant;

  align-items: center;
  font-size: 18px;
}
.marked-row[data-v-f5ff019b] {
  background-color: var(--main-undertone-clr);
}
/* .select-box-tool-box {
  direction: ltr;
  width: 150px;
  font-family: Assistant;
  background-color: var(--lighter-grey-clr);
  height: 30px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  color: var(--text-clr);
  width: 50%;
}
.search-box-container {
  margin-right: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 20px;
  position: sticky;
  gap: 14px;
  z-index: 1;
}
.search-input {
  font-size: 14px;
  color: var(--text-clr);
  border-radius: var(--8px, 8px);
  outline: 0;
  border: 0;
  background-color: var(--lighter-grey-clr);
  width: 260px;
  height: 30px;
  padding: var(--8px, 8px) var(--12px, 12px);
  gap: var(--4-px, 4px);
}

.custom-select-selected {
  cursor: pointer;
  gap: 1px;
  border: 0;
  width: 170px;
  border-radius: 8px;
  background: var(--lighter-grey-clr);
  padding: 4px 12px;
  height: 31px;
}

.custom-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: var(--white-clr);
  z-index: 10;
} */