table[data-v-9b8a63a6] {
  border: 0;
  width: 100%;
  max-height: calc(100vh - 236px);
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
tr[data-v-9b8a63a6]{
  background-color: var(--chat-background-clr);
}