/* ----------------------------------
   Title & Label Styling 
   ---------------------------------- */
.tooltip-title[data-v-54c31c43],
.section-label[data-v-54c31c43] {
  color: #000;
  font-family: "Assistant", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.section-label[data-v-54c31c43] {
  margin-bottom: 4px;
}

/* ----------------------------------
         Subtitle & Value Styling 
         (includes the experimental props)
         ---------------------------------- */
.tooltip-subtitle[data-v-54c31c43],
.section-value[data-v-54c31c43],
.pricing-item[data-v-54c31c43] {
  color: #000;
  font-family: "Assistant", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
  margin: 0;
}

/* ----------------------------------
         Tooltip Separator Line
         ---------------------------------- */
.tooltip-separator[data-v-54c31c43] {
  border: none;
  border-top: 1px solid #ddd;
  margin: 12px 0;
}

/* ----------------------------------
         Main Grid Section for "Best for"
         and "Use case"
         ---------------------------------- */
.grid-section[data-v-54c31c43] {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0; /* Remove any default padding */
  gap: 16px; /* Use gap for consistent spacing between items */
}

/* Each grid item in the section */
.grid-item[data-v-54c31c43] {
  /* Make each item a flex column so content stays top-aligned */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1;
  word-wrap: break-word;
  word-break: break-word;
}

/* ----------------------------------
         Vertical Separator
         ---------------------------------- */
.vertical-separator[data-v-54c31c43] {
  width: 1px;
  background-color: #ddd;
  align-self: stretch;
  margin: 0;
}

/* ----------------------------------
         Pricing Section (optional)
         ---------------------------------- */
.pricing-section[data-v-54c31c43] {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
}
