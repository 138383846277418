.checkbox[data-v-17aaa963] {
  display: none;
}
tbody tr[data-v-17aaa963] {
  background-color: var(--white-clr);
  border: 10px solid var(--chat-background-clr);
}
.checkbox[data-v-17aaa963] {
  display: none;
}
tr[data-v-17aaa963] {
  width: 100%;
}
thead[data-v-17aaa963] {
  width: 100px;
}
.custom-header[data-v-17aaa963] {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* .vector-modal-btn{
    background-color: transparent;
    border: 0;
    outline: 0;
} */
/* .flex-vector-methods{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
} */
/* .flex-vector-methods button{
    font-size: 18px;
    display: flex;
    gap: 16px;
} */
thead th[data-v-17aaa963] {
  position: -webkit-sticky;
  position: sticky;
  /* font-family: 'Courier New', Courier, monospace; */
  top: 0; /* Adjust this if you have any fixed element at the top of the page */
  z-index: 2;
  background-color: var(--chat-background-clr);
  padding: 13px 10px;
}
/* tr img{
    width:18px;
    height: 18px;
    
} */
td[data-v-17aaa963],
th[data-v-17aaa963] {
  min-width: 120px;
}
.question-input[data-v-17aaa963]:focus {
  background-color: var(--lighter-grey-clr);
  outline: 0;
}
.focus-bg[data-v-17aaa963] {
  background: var(--lighter-grey-clr);
  outline-width: 0;
  color: var(--text-clr);
}
table[data-v-17aaa963] {
  border: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 16px;
  border-collapse: collapse;
  font-family: Assistant;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.table-container[data-v-17aaa963] {
  /* width: 1300px; */
  /* padding-right: 20px;
    padding-left: 20px; */
  overflow: auto;
  max-height: 500px;
}
td[data-v-17aaa963] {
  vertical-align: top;
  border-right: none;
  border-left: none;
  font-size: 18px;
  padding: 13px 5px;
}
.question-input[data-v-17aaa963] {
  /* // direction: rtl; */
  resize: none;

  width: 100%;
  font-size: 18px;
  /* // min-height: 42px; */
  min-height: 48px;
  /* // max-width: 48px; */
  /* // max-width: 770px; */
  padding: 15px 10px 0px 10px;
  /* // overflow: hidden; */
  position: relative;
  font-family: Assistant;
  max-height: 500px;
  border-radius: 10px;
  font-size: 14px;
  color: var(--text-clr);

  background-color: transparent;
  border: 0;
}
.ag-theme-alpine[data-v-17aaa963] {
  --ag-borders: none;
}
.icons[data-v-17aaa963] {
  gap: 20px;
  display: flex;
}
.submit-btn-document[data-v-17aaa963]{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px;
  border: 0;
  font-size: 15px;
  /* margin-top: 10px; */
  padding: 6px 10px;
  background-color: var(--main-clr);
  color: var(--text-clr);
  cursor: pointer;
}
.submit-btn-document[data-v-17aaa963]:disabled{
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
opacity: 0.5;
cursor: inherit !important;
font-size: 15px;
border-radius: 8px;
border: 0;
/* margin-top: 10px; */
padding: 6px 10px;
background-color: var(--main-clr);
color: var(--text-clr);
cursor: pointer;
}
